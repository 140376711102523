import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import PhoneInput from "react-phone-input-2";
import { Errors, Placeholders } from "../../utils/errors";
import { RegxExpression } from "../../utils/common"
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Success_Modalss from "../../components/Success_Modal/Success_Modalss";
import { postData } from "../../utils/api";
import "react-phone-input-2/lib/bootstrap.css";

const EnquiryForm = () => {
    const [mobileVerify, setMobileVerify] = useState(false);
    const [emailVerify, setEmailVerify] = useState(false);
    const [successModalShow, setSuccessModalShow] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (d) => {
        d.api_key = "nmims_landing_page";
        clearErrors();
        const res = await postData(`https://profcymacrm.com/api/profcyma-lead-nmims`, d)
        if (res?.success) {
            console.log(res);

            reset();
            setSuccessModalShow(true);
            setTimeout(() => {
                setSuccessModalShow(false);
            }, 3000);
        } else {
            console.error('Error fetching  data:', res?.error);
        }


    };
    console.log(getValues(), "ggggggggg");
    console.log(errors, "eeeeeeeeeeeeee");


    return (
        <div className="white-bggg">
            <h5 className="enquire-now-text mb-4">Enquire Now</h5>
            <Form id="enquiry-form">
                <Form.Group className="mb-3" controlId="First_Name">
                    <Form.Control
                        type="text"
                        name="First_Name"
                        placeholder="Enter Full Name"
                        {...register("f_name", {
                            required: Errors.first_name,
                        })}
                        className={classNames("", {
                            "is-invalid": errors?.f_name,
                            "is-valid": getValues("f_name"),
                        })}
                        onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="Mobile_Number">


                    <Form.Control
                        className={classNames("", {
                            "is-invalid": errors?.contact_no,
                            "is-valid": getValues("contact_no"),
                        })}
                        type="text"
                        name="contact_no"
                        placeholder="Enter Mobile Number"
                        {...register("contact_no", {
                            required: "Contact No is required",
                            minLength: {
                                value: 10,
                                message:
                                    "Number should be at least 10 characters",
                            },
                        })}
                        onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                if (event.key !== "Backspace") {
                                    event.preventDefault();
                                }
                            }
                        }}
                        maxLength={10}
                    />
                    {errors?.contact_no && (
                        <sup className="text-danger">
                            {errors?.contact_no?.message}
                        </sup>
                    )}
                </Form.Group>


                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1 "
                >
                    <Form.Control
                        className={classNames("", {
                            "is-invalid": errors?.email,
                        })}
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: RegxExpression.email,
                                message: "Invalid email address",
                            },
                        })}
                        type="email"
                        placeholder="Email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Check
                        type="checkbox"
                        name="checkbox"
                        label="I authorize University and its representatives to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DND / NDNC.*"
                        required
                    />
                </Form.Group>

                <div className="text-end mt-4">
                    <button type="submit" className="submit-btn" onClick={handleSubmit(onSubmit)}>Submit</button>
                </div>
            </Form>
            <Success_Modalss show={successModalShow} texts={'Form Submitted Successfully'} />
        </div>

    )
}

export default EnquiryForm