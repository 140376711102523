import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './LearningResources.css'

const LearningResources = () => {
    return (
        <section className='learning-resources'>
            <Container>
                <Row>
                    <Col md={6} sm={6} xs={12}>
                        <div className='learning-title'>
                            <h2>Learning Resources</h2>
                        </div>
                    </Col>
                    <Col md={6} sm={6} xs={12}>
                        <div className='normal-white-txtt'>
                            <p>NMIMS MBA Online  offers a comprehensive learning experience that combines lecture presentations, high-quality courseware, and access to a vast digital library. Students can also receive support from course coordinators and interact with faculty members through online forums. These resources provide students with the tools and support they need to succeed in their studies and achieve their career goals.</p>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default LearningResources