import React from 'react'

const UniversityBanner = () => {
    return (
        <section className="home-banner">
            <img src={process.env.PUBLIC_URL + '/assets/images/banner/HomePage-Banner-Image-1.jpg'} className="banner-image" />
        </section>
    )
}

export default UniversityBanner