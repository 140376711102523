import React from 'react'
import './ProgrammFeatureCounter.css'
import { Col, Container, Row } from 'react-bootstrap'
import CountUp from 'react-countup';

const ProgrammFeatureCounter = () => {
    return (
        <section className='counter-section'>
            <Container >
                <div className='brown-bggg'>
                    <Row>
                        <Col lg={4} md={3} sm={12} xs={12}>
                            <h4 className='common-title'>Program Features</h4>
                        </Col>
                        <Col lg={8} md={9} sm={12} xs={12}>
                            <Row>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <h4 className='counter-number'><CountUp end={4} /></h4>
                                    <p className='counter-txtt'>Semesters</p>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <h4 className='counter-number'><CountUp end={2} /> years</h4>
                                    <p className='counter-txtt'>Durations</p>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <h4 className='counter-number'><CountUp end={4} /> years</h4>
                                    <p className='counter-txtt'>Program Validity</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='text-end'>
                    <p className='common-txtt mt-3'>The 2-year nmims mba is designed to provide working professionals the flexibility to pace the program to fit their schedule, <br /> without the need to deprioritize their professional or personal commitments.</p>
                </div>
            </Container>
        </section>
    )
}

export default ProgrammFeatureCounter