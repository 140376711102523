import React, { useState } from 'react'
import './ProgrammStructure.css'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import EnquiryModal from '../../EnquiryModal/EnquiryModal'

const features = [
    {
        title: 'Seamless Career Transformation -',
        description: 'The program is designed to help students advance their careers and achieve their professional goals.',
    },
    {
        title: 'Case-Based and Application-Oriented Assessment -',
        description: 'Students are evaluated through a combination of theoretical assessments and practical applications, ensuring that they can analyze business problems and implement appropriate solutions.',
    },
    {
        title: 'Flexible Learning -',
        description: 'The online format allows students to balance their studies with work and other commitments.',
    }
]

const ProgrammStructure = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <section className='prgramm-outcomes'>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <h2 className='common-title'>Program Structure </h2>
                        <p className='common-subitlte'>
                            NMIMS MBA Online is a four-semester program designed to provide a comprehensive and flexible learning experience. The program combines theoretical knowledge with practical application to ensure that students are well-prepared for the challenges of the modern business world.
                        </p>
                        {features.map((feature, index) => (
                            <div className='d-flex mt-4' key={index}>
                                <FontAwesomeIcon icon={faCheck} className='check-icon' />
                                <div className='text-programmm'>
                                    <h5>{feature.title}</h5>
                                    <p className='common-txtt'>{feature.description}</p>
                                </div>
                            </div>
                        ))}
                        <div className='mt-4'>
                            <button className='enquiry-btnnn' onClick={() => setModalShow(true)}>
                                Enquire Now
                            </button>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Table striped bordered responsive>
                            <thead>
                                <tr>
                                    <th>Semester</th>
                                    <th>Subject</th>
                                    <th>Subject Credits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Semester 1</td>
                                    <td>
                                        <p>Business Communication</p>
                                        <p>Financial Accounting</p>
                                        <p>Micro Economics & Macro Economics</p>
                                        <p>Organizational Behavior</p>
                                        <p>Marketing Management</p>
                                        <p>Quantitative Method - 1</p>
                                    </td>
                                    <td>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Semester 2</td>
                                    <td>
                                        <p>Cost & Management Accounting</p>
                                        <p>Human Resource Management</p>
                                        <p>Strategic Management</p>
                                        <p>Business Analytics</p>
                                        <p>Legal Aspect of Business</p>
                                        <p>Operations Management</p>
                                    </td>
                                    <td>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Semester 3</td>
                                    <td>
                                        <p>Corporate Finance</p>
                                        <p>Research Methodology</p>
                                        <p>Project Part 1</p>
                                        <p>Effective 1</p>
                                        <p>Effective 2</p>
                                        <p>Effective 3</p>
                                        <p>Effective 4</p>
                                    </td>
                                    <td>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Semester 4</td>
                                    <td>
                                        <p>Indian Ethos & Ethics</p>
                                        <p>Corporate Sustainability</p>
                                        <p>International Business</p>
                                        <p>Project Part 2</p>
                                        <p>Effective 5</p>
                                        <p>Effective 6</p>
                                        <p>Effective 7</p>
                                    </td>
                                    <td>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                        <p>4 Credits</p>
                                    </td>
                                </tr>

                            </tbody>
                        </Table>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div>
                            <h2>Program Specialisations</h2>
                            <Table striped bordered responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Semester</th>
                                        <th>Subject</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Semester 1</td>
                                        <td>
                                            Finance
                                        </td>
                                        <td>
                                            <p>1. Capital Market & Portfolio Management</p>
                                            <p>2. Business Valuation</p>
                                            <p>3. Financial Derivatives</p>
                                            <p>4. Strategic Cost Management</p>
                                            <p>5. Corporate Tax Planning</p>
                                            <p>6. Investment Banking</p>
                                            <p>7. International Finance</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Semester 2</td>
                                        <td>
                                            Human Resource
                                        </td>
                                        <td>
                                            <p>1. Compensation & Benefits</p>
                                            <p>2. Industrial Relations & Labour Laws</p>
                                            <p>3. Manpower Planning, Recruitment and Selection</p>
                                            <p>4. Organisational Development & Change</p>
                                            <p>5. Performance Management System</p>
                                            <p>6. Learning & Development</p>
                                            <p>7. Emotional Intelligence</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Semester 3</td>
                                        <td>
                                            Operations & Data Sciences
                                        </td>
                                        <td>
                                            <p>1. Quantitative Methods - 2</p>
                                            <p>2. Supply Chain Management</p>
                                            <p>3. Project Management</p>
                                            <p>4. Service Operations</p>
                                            <p>5. Total Quality Management</p>
                                            <p>6. Six Sigma</p>
                                            <p>7. Strategic Sourcing & E-procurement</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Semester 4</td>
                                        <td>
                                            Marketing
                                        </td>
                                        <td>
                                            <p>1. Brand Management</p>
                                            <p>2. Consumer Behaviour</p>
                                            <p>3. Integrated Marketing Communications</p>
                                            <p>4. Sales Management</p>
                                            <p>5. International Marketing</p>
                                            <p>6. Services Marketing</p>
                                            <p>7. Digital Marketing</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Semester 4</td>
                                        <td>
                                            Marketing
                                        </td>
                                        <td>
                                            <p>1. Information Systems for Management</p>
                                            <p>2. Consumer Behaviour</p>
                                            <p>3. Organisational Theory, Structure and Design</p>
                                            <p>4. Supply Chain Management</p>
                                            <p>5. Digital Marketing Mbr 6. Entrepreneurship and Venture Capital Management</p>
                                            <p>6. Project Management</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
            <EnquiryModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    )
}

export default ProgrammStructure
