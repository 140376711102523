import React, { useState, useRef } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './CoreSpecialization.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
const CoreSpecialization = () => {
    const [isShown, setIsShown] = useState(false);
    const contentRef = useRef(null);
    const toggleParagraph = () => {
        setIsShown(!isShown);
    };
    return (
        <section className='core-specialization programm-highlight'>
            <div className='learning-approach '>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <h5 className='common-title'>Learning Approach </h5>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <p className='common-subtitle '>NMIMS MBA Online  offers a comprehensive learning experience that combines lecture presentations, high-quality courseware, and access to a vast digital library. Students can also receive support from course coordinators and interact with faculty members through online forums. These resources provide students with the tools and support they need to succeed in their studies and achieve their career goals.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Programm Structure */}
            <div className='programm-structure'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <h5 className='common-title'>Program Structure  </h5>
                            <p className='common-subtitle '>The 2 year, 90 credit, programme is designed to build your academic forte in the initial months, followed by immediate application with a Capstone Business Simulation Project and an industry project.</p>
                            <div className='mt-4'>
                                <button className='readmore-btnnn' onClick={toggleParagraph}>
                                    <FontAwesomeIcon
                                        icon={faCaretRight}
                                        className={`icon ${isShown ? 'rotate' : ''}`}
                                    />
                                    Read More
                                </button>
                                <div
                                    className={`content-wrapper ${isShown ? 'expand' : ''}`}
                                    ref={contentRef}
                                    style={{
                                        height: isShown ? `${contentRef.current.scrollHeight}px` : '0px'
                                    }}
                                >
                                    <p className="content">
                                        A subsidiary of the renowned Harvard University, the foundation module from Harvard Business Publishing Education aims to acquaint students with terminologies before beginning the Programme and improve management practice; it serves as a bridge between academia and enterprises.
                                        The core subjects aim to create a fundamental base and grounding for the programme. These subjects train the candidates in general business management i.e an overview of handling the core operations of any company. The core subjects constitute the founding pillars for successful business management professionals.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className='brown-bggg'>
                                <h4>Core Specialisation</h4>
                                <p>A student should choose 6 electives in Terms III, IV & V from that particular track (out of 5 tracks). The remaining 6 electives can be chosen from any tracks.</p>

                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/banner/course-content.webp'} className='structure-img' />
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className='red-bggg'>
                                <h4>Dual Specialisation</h4>
                                <p>A student should choose 6 electives in Terms III, IV & V from that particular track (out of 5 tracks). The remaining 6 electives can be chosen from any tracks.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default CoreSpecialization
