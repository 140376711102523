import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import { faBars, faBookOpen, faChartLine, faLaptopCode, faPaintbrush } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ProgrammHighlights.css'

const ProgrammHighlights = () => {
    return (
        <section className='programm-highlight'>
            <Container>
                <div>
                    <h2 className='common-title'>Program Highlights -</h2>
                    <h2 className='common-title'>NMIMS MBA (Working Executives)</h2>
                    <p className='common-subtitle'>SVKM’s Narsee Monjee Institute of Management was founded in 1981 and achieved Deemed-to-be-University status from the University Grants Commission in 2003. With over 4 decades of history in providing stellar academics, SVKM’s NMIMS Deemed-to-be-University is now a multidisciplinary centre of learning. It offers its students a rich balanced environment to learn and grown in, and exposure to research & academics. It provides opportunities to have hands-on access to practical aspects of diverse functions across industries. </p>
                </div>
                <Row>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faClipboard} className='font-iconnn' />
                        <p className='common-subtitle'>Globally Recognized</p>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faPaintbrush} className='font-iconnn' />
                        <p className='common-subtitle'>Flexible Learning</p>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faBars} className='font-iconnn' />
                        <p className='common-subtitle'>Industry-Relevant Curriculum</p>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faBookOpen} className='font-iconnn' />
                        <p className='common-subtitle'>Career Support</p>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faClipboard} className='font-iconnn' />
                        <p className='common-subtitle'>Experienced Faculty</p>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faClipboard} className='font-iconnn' />
                        <p className='common-subtitle'>Interactive Learning</p>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faLaptopCode} className='font-iconnn' />
                        <p className='common-subtitle'>Networking Opportunities</p>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <FontAwesomeIcon icon={faChartLine} className='font-iconnn' />
                        <p className='common-subtitle'>Specializations</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ProgrammHighlights
