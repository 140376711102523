import React, { useState, useRef } from 'react'
import './ProgrammHighlight.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

const ProgrammHighlight = () => {
    const [isShown, setIsShown] = useState(false);
    const contentRef = useRef(null);
    const toggleParagraph = () => {
        setIsShown(!isShown);
    };
    return (
        <section className='programm-highlight'>
            <Container>
                <div >
                    <h2 className='common-title'>NMIMS MBA - Program Highlights</h2>
                </div>
                <Row>
                    <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                        <p className='high-texttt'>NMIMS Online MBA is a prestigious program offered by the Narsee Monjee Institute of Management Studies, a globally recognized university with over four decades of academic excellence. This program is designed for working professionals seeking to advance their careers through flexible online learning. </p>
                        <div className='mt-4'>
                            <button className='readmore-btnnn' onClick={toggleParagraph}>
                                <FontAwesomeIcon
                                    icon={faCaretRight}
                                    className={`icon ${isShown ? 'rotate' : ''}`}
                                />
                                Read More
                            </button>
                            <div
                                className={`content-wrapper ${isShown ? 'expand' : ''}`}
                                ref={contentRef}
                                style={{
                                    height: isShown ? `${contentRef.current.scrollHeight}px` : '0px'
                                }}
                            >
                                <p className="content">
                                    The curriculum is industry-relevant, taught by experienced faculty, and offers interactive learning experiences.
                                    Additionally, students have access to a vast network of alumni and industry professionals, along with career
                                    guidance and placement assistance. With various specializations available, NMIMS Online MBA provides a valuable
                                    qualification for those looking to enhance their career prospects and gain a competitive edge in the business world.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ProgrammHighlight