import React, { useState } from "react";
import "./Banner.css";
import { Col, Container, Row, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import EnquiryForm from "../../EnquiryForm/EnquiryForm";



function Banner() {
  const [phone, setPhone] = useState("");
  return (
    <section className="home-banner">
      <div className="container-fluid ">
        <img src={process.env.PUBLIC_URL + '/assets/images/banner/img2.jpg'} className="banner-image" />
        <div className="mobile-view-title">
          <p className="banner-title-mobile">Master of Business Administration - NMIMS</p>
        </div>
        <div className="banner-data">
          <Container>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <div className="main-class-head">
                  <p className="banner-title">Master of Business</p>
                  <p className="banner-title">Administration - NMIMS</p>
                </div>
              </Col>
              <Col md={6} sm={12} xs={12}>
                <EnquiryForm />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default Banner;
