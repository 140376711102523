import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import EnquiryForm from '../EnquiryForm/EnquiryForm';

const EnquiryModal = (props) => {

    return (
        <section className='enquiry-modal'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <EnquiryForm />
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default EnquiryModal