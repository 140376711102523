import { faCaretRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ProgrammOutcomes.css';

const ProgrammOutcomes = () => {
    const [activeSection, setActiveSection] = useState(null); // Track which section is expanded
    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);

    const toggleParagraph = (section) => {
        if (activeSection === section) {
            setActiveSection(null);
        } else {
            setActiveSection(section);
        }
    };

    return (
        <section className='programm-highlight program-outtt'>
            <Container>
                <Row>
                    <div>
                        <h2 className='common-title'>Program Outcomes</h2>
                        <p className='common-subtitle'>The programme offers intensive academics to enable knowledge empowerment and holistic development so that you grow into a responsive leader in a short period.</p>
                        <p className='red-txttt'><FontAwesomeIcon icon={faCheck} className='font-iconnn me-3' />Modules from Harvard Business Publishing Education</p>
                        <p className='red-txttt'><FontAwesomeIcon icon={faCheck} className='font-iconnn me-3' />Robust Curriculum</p>
                        <p className='red-txttt'><FontAwesomeIcon icon={faCheck} className='font-iconnn me-3' />Power-packed learning</p>
                        <p className='red-txttt'><FontAwesomeIcon icon={faCheck} className='font-iconnn me-3' />Internal Assessment & Term End Examinations for enhanced retention</p>
                    </div>
                    {/* Program Feature */}
                    <div className='programm-feature mt-5'>
                        <h2 className='common-title'>Program Features</h2>
                        <p className='common-subtitle'>2 years of experiential learning – The programme enables you to take on the corporate challenges of the business world effectively through an application-based experiential approach.</p>
                        <Row className='mt-5'>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <img src={process.env.PUBLIC_URL + '/assets/images/banner/Untitled-design-56.png'} className='feature-img' />
                                <h5 className='red-txttt mt-3'>Networking</h5>
                                <p className='common-txtt'>Network with peers and interact with faculty through the on-campus programme immersion.</p>
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <img src={process.env.PUBLIC_URL + '/assets/images/banner/Untitled-design-57.png'} className='feature-img' />
                                <h5 className='red-txttt mt-3'>Capstone Project</h5>
                                <p className='common-txtt'>It is the global standard for management programs, used by more than 600 business schools across the world,</p>
                                <div className='mt-2'>
                                    <button
                                        className={`readmore-btnnn ${activeSection === 'capstone' ? 'active' : ''}`} // Apply 'active' class when expanded
                                        onClick={() => toggleParagraph('capstone')}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCaretRight}
                                            className={`icon ${activeSection === 'capstone' ? 'rotate' : ''}`}
                                        />
                                        Read More
                                    </button>
                                    <div
                                        className={`content-wrapper ${activeSection === 'capstone' ? 'expand' : ''}`}
                                        ref={contentRef1}
                                        style={{
                                            height: activeSection === 'capstone' ? `${contentRef1.current.scrollHeight}px` : '0px'
                                        }}
                                    >
                                        <p className="content">
                                            including Wharton, Harvard, Kellogg, etc. It offers you an excellent ecosystem to synthesise & apply concepts and techniques from core subjects and specialisations.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <img src={process.env.PUBLIC_URL + '/assets/images/banner/Untitled-design-58.png'} className='feature-img' />
                                <h5 className='red-txttt mt-3'>Project Duration</h5>
                                <p className='common-txtt'>The project of six-month duration is a mandatory component of the program.</p>
                                <div className='mt-2'>
                                    <button
                                        className={`readmore-btnnn ${activeSection === 'project' ? 'active' : ''}`}
                                        onClick={() => toggleParagraph('project')}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCaretRight}
                                            className={`icon ${activeSection === 'project' ? 'rotate' : ''}`}
                                        />
                                        Read More
                                    </button>
                                    <div
                                        className={`content-wrapper ${activeSection === 'project' ? 'expand' : ''}`}
                                        ref={contentRef2}
                                        style={{
                                            height: activeSection === 'project' ? `${contentRef2.current.scrollHeight}px` : '0px'
                                        }}
                                    >
                                        <p className="content">
                                            Students need to submit a video on the project that they are doing at their workplace or on their own at the end of Term 7. At the end of Term 8, the student will submit a reflective essay.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </Container>
        </section>
    );
};

export default ProgrammOutcomes;
