import React from "react";
import "./Success_Modalss.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Success from "./SuccessfulLotti/Success";

function Success_Modalss(props) {
  return (
    <>
      <section className="successmaodal-section">
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="succs-modl"
        >
          <div className="modals-top">
            <Modal.Header className="modal-heading">
            </Modal.Header>

            <div className="loginlogomain">
              {/* <img
                className="loglogo"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Websitelogo/loginlogo.png"
                }
                alt="social_img"
              /> */}

              <Success />
            </div>
          </div>
          <Modal.Body className="modalssbody">
            <p className="modalcontent">
              {props.texts}
            </p>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default Success_Modalss;
