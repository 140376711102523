export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export const RequiredIs = {
  roles: [1, 2, 3, 4, 5, 6, 7],
};

export const InputeType = {
  1: {
    value: /^[0-9,]*$/,
    message: "Only numbers are allowed",
  },
  2: {
    value: /[A-Z-a-z,]/,
    message: "Only Charector are allowed",
  },
  3: {
    value: /^[-+]?[0-9,]*\.[0-9]+$/,
    message: "Only Numbers and Character are allowed",
  },
  // 4: /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,
  4: {
    value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*,])[A-Za-z\d!@#$%^&*,]{8,}$/,
    message: "Only Special Characters are allowed",
  },
  5: {
    value: /[\s\S]*/g,
    message: "Accept All",
  },
};

export const Paths = {
  o_w_seller_product: "/seller/seller-dashboard/sellerlistnewproduct",
  o_w_seller_product_edit: "/seller/seller-dashboard/sellerproduct",
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const SelectHsnData = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name + " " + data?.gst + "%",
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select3Data = async (data, product_title, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.product_title,
    name: product_title,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: product_title });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const RegxExpression = {
  name: /[A-Z-a-z ]/,
  email:
    /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
  phone: /[0-9]/,
  number: /^[0-9]*$/,
  ifsc: "^[A-Z]{4}[0][dA-Z]{6}$",
  ifsc_code: /^[A-Z0-9]$/,
  micr: /^[0-9]{9}$/,
  bank_number: /^\d{0,14}$/,
  gst: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
};
