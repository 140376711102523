import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import './FaqTab.css'

const FaqTab = () => {
    return (
        <section className='faqtab'>
            <Container>
                <div className='mb-5'>
                    <h5 className='common-title'>Frequently Asked Questions</h5>
                </div>
                <Accordion defaultActiveKey="0" className='faq-accordian'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Is NMIMS good for MBA</Accordion.Header>
                        <Accordion.Body>
                            Yes, NMIMS is considered one of the top business schools in India, offering a strong MBA program with a comprehensive curriculum, experienced faculty, and excellent placement opportunities. It is highly regarded for its industry connections and diverse specializations.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>How much does MBA from NMIMS cost?</Accordion.Header>
                        <Accordion.Body>
                            The cost of an MBA from NMIMS varies depending on the program and campus, but it typically ranges between ₹10-20 lakhs for the entire course. It’s recommended to check the specific program details for the most accurate and up-to-date fee structure.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>How hard is it to get into NMIMS MBA?</Accordion.Header>
                        <Accordion.Body>
                            Getting into NMIMS MBA is competitive, with a high cutoff in the NMAT exam and a rigorous selection process including a group discussion and personal interview. Strong academic records and extracurricular achievements also play a key role.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>What is average MBA salary in NMIMS?</Accordion.Header>
                        <Accordion.Body>
                            The average MBA salary for graduates from NMIMS typically ranges between ₹15-20 lakhs per annum, depending on the specialization and industry, with top roles often offering even higher packages.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>What are the eligibility criteria for the NMIMS Online MBA program?</Accordion.Header>
                        <Accordion.Body>
                            The eligibility criteria for the NMIMS MBA Online  program include a bachelor’s degree in any discipline from a recognized university with at least 50% aggregate marks. Additionally, candidates with two or more years of work experience are preferred.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>What is the duration of the program?</Accordion.Header>
                        <Accordion.Body>
                            The duration of the program is typically **two years**, divided into **four semesters**. However, the specific duration may vary depending on the institution and the mode of study (full-time or part-time).
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>What is the fee structure for the program?</Accordion.Header>
                        <Accordion.Body>
                            The fee structure for the program varies depending on the specific course and duration. For detailed information, please visit the program’s official website or contact the admissions office directly.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>When does the application process start and end?</Accordion.Header>
                        <Accordion.Body>
                            The application process for the NMIMS MBA Online  typically starts in January and ends in May. However, exact dates may vary each year, so it’s recommended to check the university’s official website for the most current information.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>What is the entrance exam for NMIMS MBA?</Accordion.Header>
                        <Accordion.Body>
                            The entrance exam for the NMIMS MBA Online  program is the NMAT by GMAC. Candidates must register for and take the NMAT exam to be considered for admission.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>

        </section>
    )
}

export default FaqTab