import React from 'react'
import './EnquireNowBtn.css'

const EnquireNowBtn = () => {
    return (
        <div>
            <button className='enquire-now-btnn' onClick={() => window.scrollTo(0, 0)}>
                Enquire Now
            </button>
        </div>
    )
}

export default EnquireNowBtn