import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ProgrammFee.css'
import EnquiryModal from '../../EnquiryModal/EnquiryModal'

const ProgrammFee = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <section className='programm-highlight program-outtt'>
            <Container>
                <h2 className='common-title'>Program Fee</h2>
                <Row>
                    <Col xl={4} lg={4} md={6} xs={12}>
                        <div className='main-programm-fee mt-5 mb-5'>
                            <p className='fee-title'>Option 1 : Annual payment (in INR)</p>
                            <h5 className='amount-txtt'>₹ 1,05,000/-</h5>
                            <p className='no-of-txtt'>No. of Years : 2</p>
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} xs={12}>
                        <div className='main-programm-fee mt-5 mb-5'>
                            <p className='fee-title'>Option 2 : Semester fee payment (in INR)</p>
                            <h5 className='amount-txtt'>₹ 55,000/-</h5>
                            <p className='no-of-txtt'>No. of Semesters : 4</p>
                        </div>
                    </Col>
                    <div>
                        <div>
                            <ul>
                                <li>Admission Processing Fee of ₹1,200/- applicable for all admissions.</li>
                                <li>An initial amount of ₹10,000/- from the program fee will be collected at the time of registration.</li>
                                <li>Exam fee : ₹800/- per subject per attempt.</li>
                                <li>Project fee : ₹1,500/- per exam attempt.</li>
                                <li>Students also have the option to pay the full program fee of ₹196,000/- if they prefer.</li>
                                <li>The above-mentioned fee structure is subject to change at the discretion of the University.</li>
                                <li>Any payment made via Demand Draft should be made in favour of “SVKM’s NMIMS” payable at Mumbai.</li>
                                <li>Now avail loan facility to pay fees for the Program even without a credit card</li>
                                <li>EMI Facility (3, 6, 9, 12 months) available via credit cards of the following banks: HDFC Bank, ICICI Bank, Axis Bank, Citi Bank, Standard Chartered Bank, HSBC Bank, Kotak Mahindra Bank</li>
                            </ul>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <button className='enquiry-btnnn' onClick={() => setModalShow(true)}>
                            Enquire Now
                        </button>
                    </div>
                </Row>
            </Container>
            <EnquiryModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    )
}

export default ProgrammFee