import React from 'react'
import './FacultyTab.css'
import { Col, Container, Row } from 'react-bootstrap'

const FacultyTab = ({ imgSrc, facultytitle, facultytext }) => {
    return (
        <section className='faculty-tab'>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <img src={process.env.PUBLIC_URL + imgSrc} className='faculty-img' />
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className='faculty-text'>
                            <h5>{facultytitle}</h5>
                            <p>{facultytext}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FacultyTab