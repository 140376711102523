import React from 'react';
import './ProgrammObejctive.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader, faChartLine, faClipboard, faLaptopCode, faPaintbrush, faSquarePollHorizontal } from '@fortawesome/free-solid-svg-icons';

const imageList = [
    'Image-1.jpg',
    'Image-2.jpg',
    'Image-3.jpg',
    'Image-4.jpg'
];

const objectivesList = [
    {
        icon: faClipboard,
        title: 'Strategic Thinking and Decision Making',
        subtitle: 'Develop the ability to analyze complex business problems and make sound strategic decisions.'
    },
    {
        icon: faPaintbrush,
        title: 'Leadership and Management Skills',
        subtitle: 'Learn the key leadership and management skills necessary to lead teams and projects effectively.'
    },
    {
        icon: faSquarePollHorizontal,
        title: 'Ethical Decision Making',
        subtitle: 'Understand ethical principles and make responsible business decisions.'
    },
    {
        icon: faBookOpenReader,
        title: 'Lifelong Learning',
        subtitle: 'Cultivate a mindset of lifelong learning and continuous professional development.'
    },
    {
        icon: faLaptopCode,
        title: 'Global Business Perspective',
        subtitle: 'Gain a global perspective on business practices and understand the challenges and opportunities of operating in international markets.'
    },

    {
        icon: faChartLine,
        title: 'Innovation and Entrepreneurship',
        subtitle: 'Foster a culture of innovation and entrepreneurship, encouraging students to think creatively and identify new business opportunities.'
    },
    {
        icon: faClipboard,
        title: 'Analytical and Problem-Solving Skills',
        subtitle: 'Develop strong analytical and problem-solving skills to address complex business challenges.'
    },


];

const ProgrammObjective = () => {
    return (
        <section className='programm-objective'>
            <Container>
                <Row>
                    {imageList.map((image, index) => (
                        <Col key={index} lg={3} md={3} sm={6} xs={12}>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/banner/${image}`}
                                className='programm-img'
                                alt={`Program Image ${index + 1}`}
                            />
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col md={12} className='mb-4'>
                        <div className='mt-5'>
                            <h2 className='common-title'>Program Objectives</h2>
                            <p className='common-subtitle'>NMIMS Online MBA is designed to equip students with the following key objectives:</p>
                        </div>
                    </Col>
                    {objectivesList.map((objective, index) => (
                        <Col key={index} lg={6} md={6} sm={6} xs={12}>
                            <div className='d-flex mb-3'>
                                <FontAwesomeIcon icon={objective.icon} className='font-iconnnn' />
                                <div>
                                    <h5 className='object-title'>{objective.title}</h5>
                                    <p className='object-subtitle'>{objective.subtitle}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default ProgrammObjective;
