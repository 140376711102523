import "./banner/Banner.css";
import Banner from "./banner/Banner";
import ProgrammHighlight from "./ProgrammHighlight/ProgrammHighlight";
import ProgrammObejctive from "./ProgrammObejctive/ProgrammObejctive";
import LearningApproach from "./LearningApproach/LearningApproach";
import LearningResources from "./LearningResources/LearningResources";
import ProgrammStructure from "./ProgrammStructure/ProgrammStructure";
import FacultyTab from "./FacultyTab/FacultyTab";
import ApplyOnline from "./ApplyOnline/ApplyOnline";
import UniversityBanner from "./UniversityBanner/UniversityBanner";
import FaqTab from "./FaqTab/FaqTab";
import ProgrammHighlights from "./ProgrammHighlights/ProgrammHighlights";
import ProgrammOutcomes from "./ProgrammOutcomes/ProgrammOutcomes";
import CoreSpecialization from "./CoreSpecialization/CoreSpecialization";
import WhatsappButton from "./WhatsappButton/WhatsappButton";
import ProgrammFeatureCounter from "./ProgrammFeatureCounter/ProgrammFeatureCounter";
import EnquireNowBtn from "./EnquireNowBtn/EnquireNowBtn";
import ProgrammFee from "./ProgrammFee/ProgrammFee";
import EnquiryModal from "../EnquiryModal/EnquiryModal";
import { useEffect, useState } from "react";

function Home() {



  const [status, setStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!status) {
        setShowModal(true);
        setStatus(true); // This will ensure it's shown only once
      }
    }, 10000); // 10 seconds delay

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, [status]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <>
      <Banner />
      <ProgrammHighlight />
      <ProgrammObejctive />
      <LearningApproach />
      <ProgrammFeatureCounter />
      <LearningResources />
      <ProgrammStructure />
      <FacultyTab
        imgSrc="/assets/images/banner/Image-5.jpg"
        facultytitle="Faculty"
        facultytext="NMIMS MBA Online  boasts a distinguished faculty comprising renowned academicians, industry experts, and seasoned professionals. The program’s academic reputation is upheld by a team of top-notch faculty members who bring a wealth of experience and expertise to the classroom. These faculty members are highly qualified, with many holding Ph.D. degrees and having published extensively in their respective fields. Their industry expertise ensures that the curriculum is relevant and up-to-date, providing students with valuable insights and practical guidance."
      />
      <ApplyOnline />
      <FaqTab />
      <UniversityBanner />
      <ProgrammHighlights />
      <FacultyTab
        imgSrc="/assets/images/banner/Image-5.jpg"
        facultytitle="Program Objectives"
        facultytext="NMIMS CDOE is the distance and online education centre of NMIMS University. NMIMS CDOE began its journey in 2013 with a state of the art learning management system to provide interactive learning on connected platforms 24×7. NMtIMS CDOE is changing the dynamics of higher education delivery in India while empowering students across India and enabling them to fulfil their dreams and aspirations. NMIMS CDOE has over thousands of students and over 55,000 alumi, many of who are in the top echelons of over 8,000 corporate firms across the country"
      />
      <ProgrammOutcomes />
      <ProgrammFee />
      <CoreSpecialization />
      <EnquireNowBtn />
      <WhatsappButton />

      <EnquiryModal 
      show={showModal }
      onHide={handleCloseModal}
      />
    </>
  );
}

export default Home;
