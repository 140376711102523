import React, { useState } from 'react';
import './ApplyOnline.css';
import { Col, Container, Row } from 'react-bootstrap';
import EnquiryModal from '../../EnquiryModal/EnquiryModal';

const steps = [
    {
        title: 'Registration',
        description: 'Visit online.nmims.edu and register online. After registration, a student counselor will contact you to guide you through the process.',
    },
    {
        title: 'Document Submission',
        description: 'Upload scanned copies of the following documents -',
        points: [
            '1. Gazette-attested photocopies of your academic transcripts (mark sheets & degree certificates)',
            '2. KYC documents (proof of identity and address)',
        ],
    },
    {
        title: 'Fee Submission',
        description: 'Confirm your admission by paying the program fee online. Alternatively, send a demand draft in favor of "SVKM\'s NMIMS" payable at Mumbai.',
    },
    {
        title: 'Document Submission',
        description: 'Once your documents, payment, and student verification are approved, your admission will be confirmed. The university will then issue you a student number.',
    },
];

const ApplyOnline = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <section className='apply-online'>
            <Container>
                <div className='main-apply mb-5'>
                    <h2>How to Apply for Online MBA from NMIMS?</h2>
                </div>
                <Row>
                    {steps.map((step, index) => (
                        <Col key={index} xl={3} lg={4} md={6} sm={16} xs={12}>
                            <div className='brown-bggg'>
                                <h5>{step.title}</h5>
                                <p>{step.description}</p>
                                {step.points && step.points.map((point, i) => (
                                    <p key={i}>{point}</p>
                                ))}
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            {/* Why Choose */}
            <div className='why-chhose'>
                <Container>
                    <Row>
                        <Col xl={7} lg={7} md={7} sm={16} xs={12}>
                            <h2 className='common-title'>Why Choose MBA from NMIMS?</h2>
                            <p className='common-subtitle'>NMIMS MBA Online  offers a comprehensive and flexible learning experience that can elevate your career to new heights. With its globally recognized brand, industry-relevant curriculum, experienced faculty, and interactive learning platform, NMIMS Online MBA provides you with the tools and support you need to succeed. Join the ranks of successful professionals who have chosen NMIMS Online MBA and unlock your full potential.</p>
                            <div className='mt-4'>
                                <button className='enquiry-btnnn' onClick={() => setModalShow(true)}>
                                    Enquire Now
                                </button>
                            </div>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={16} xs={12}>
                            <img src={process.env.PUBLIC_URL + '/assets/images/banner/Image-6.jpg'} className="why-image" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <EnquiryModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    );
}

export default ApplyOnline;
