import React from 'react'
import './LearningApproach.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const LearningApproach = () => {
    return (
        <section className='learning-appraoch'>
            <Container>
                <div>
                    <h2 className='common-title'>Learning Approach - The Four Quadrant Approach</h2>
                    <p className='common-subtitle'>NMIMS Online MBA employs a comprehensive learning approach that combines various elements to ensure a high-quality learning experience:</p>
                    <Row className='mt-4'>
                        <Col lg={4} md={4} sm={6} xs={12}>
                            <div className='main-divvv'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/banner/Course-Content-1.jpg'} className='learning-img' />
                                <div className='main-content-class'>
                                    <h5>Course Content</h5>
                                    <p>Students have access to recorded videos, digital study materials, and live lectures covering the entire syllabus.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={6} xs={12}>
                            <div className='main-divvv'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/banner/Course-Content-1.jpg'} className='learning-img' />
                                <div className='main-content-class'>
                                    <h5>Student Portal</h5>
                                    <p>The student portal provides access to the learning management system and student administrative system.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={6} xs={12}>
                            <div className='main-divvv'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/banner/Live-Lectures-2.jpg'} className='learning-img' />
                                <div className='main-content-class'>
                                    <h5>Live Lectures</h5>
                                    <p>Students participate in live lectures, including teaching sessions, doubt clearing sessions, industry expert sessions, session podcasts, case study discussions, and masterclasses from global experts.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* Programm Outcomes */}
                <div className='prgramm-outcomes'>
                    <h2 className='common-title'>Program Outcomes</h2>
                    <Row className='mt-4'>
                        <Col md={4} sm={6} xs={12}>
                            <div className='d-flex'>
                                <FontAwesomeIcon icon={faCheck} className='check-icon' />
                                <div className='text-programmm'>
                                    <h5>Seamless Career Transformation -</h5>
                                    <p className='common-txtt'>The program is designed to help students advance their careers and achieve their professional goals.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                            <div className='d-flex'>
                                <FontAwesomeIcon icon={faCheck} className='check-icon' />
                                <div className='text-programmm'>
                                    <h5>Flexible Learning</h5>
                                    <p className='common-txtt'>Study anytime, anywhere with 24/7 access to all lectures, live and recorded.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                            <div className='d-flex'>
                                <FontAwesomeIcon icon={faCheck} className='check-icon' />
                                <div className='text-programmm'>
                                    <h5>Industry Recognition</h5>
                                    <p className='common-txtt'>NMIMS CDOE programs are highly regarded and recognized by top corporate firms in India due to their academic rigor, industry-focused curriculum, and rich academia.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                            <div className='d-flex'>
                                <FontAwesomeIcon icon={faCheck} className='check-icon' />
                                <div className='text-programmm'>
                                    <h5>Career Services</h5>
                                    <p className='common-txtt'>Get career guidance and support from NMIMS CDOE's Career Services team to help you achieve your career goals.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                            <div className='d-flex'>
                                <FontAwesomeIcon icon={faCheck} className='check-icon' />
                                <div className='text-programmm'>
                                    <h5>Global Alumni Network</h5>
                                    <p className='common-txtt'>Be part of a global alumni network working in some of the top 8000+ corporate firms, SMBs, and startups in India and overseas.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default LearningApproach